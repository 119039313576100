<template>
    <comp-table title="数据库使用情况记录" :columns="columns" :isPage="false" :searchData="searchData" :dataList="dataList" :dataBefore="onDataBefore" @on-reset="onReset">
        <template v-slot:search="data">
            <DatePicker v-model="data.search.date" type="daterange" style="width: 250px" placeholder="时间段"></DatePicker>
        </template>
    </comp-table>
</template>

<script>
import Route from "../../communitymobilization/entity/Route"
import CompTable from "../../jointly/components/comp-table.vue"
import Request from "../../jointly/utils/request"

// class Req {
//     static get(url) {
//         return new Promise((resolve, reject) => {
//             const xhr = new XMLHttpRequest()

//             xhr.open("GET", "https://api.gateway.prod.liefengtech.com" + url, true)

//             xhr.setRequestHeader("Authorization", "b413d6a5-3fc4-4450-89ab-636ad3d99c4f")

//             xhr.onreadystatechange = function() {
//                 if (xhr.readyState === 4) {
//                     if (xhr.status === 200) {
//                         const data = JSON.parse(xhr.responseText)
//                         resolve(data.data || data.dataList)
//                     } else {
//                         reject(xhr.responseText)
//                     }
//                 }
//             }

//             xhr.send()
//         })
//     }
// }

export default {
    components: { CompTable },

    data() {
        return {
            columns: [
                {
                    title: "功能名称",
                    key: "name",
                    minWidth: 300,
                },
                {
                    title: "操作机构数",
                    minWidth: 150,
                    render: (h, params) => {
                        return h(
                            "p",
                            {
                                style: {
                                    color: "#409EFF",
                                    cursor: "pointer",
                                },
                                on: {
                                    click: () => {
                                        Route.jump(`/monitoringdatabateorg?n=${params.row.name}&s=${this.startDate}&e=${this.endDate}`)
                                    },
                                },
                            },
                            params.row.ov
                        )
                    },
                },
                {
                    title: "操作人数",
                    key: "uv",
                    minWidth: 150,
                },
                {
                    title: "查看次数",
                    key: "pv",
                    minWidth: 150,
                },
            ],

            dataList: null,

            searchData: {
                date: [
                    (() => {
                        const date = new Date()
                        date.setDate(date.getDate() - 7)
                        return date
                    })(),
                    new Date(),
                ],
            },
        }
    },

    mounted() {
        this.onLoadData()
    },

    methods: {
        onLoadData() {
            this.getTableData(this.$core.formatDate(this.searchData.date[0], "yyyy-MM-dd"), this.$core.formatDate(this.searchData.date[1], "yyyy-MM-dd"))
        },

        getTableData(startDate, endDate) {
            this.startDate = startDate
            this.endDate = endDate

            Request.get(`/gateway/api/ck/SystemOperateLog/getFunctionRequestStatDetail?startDate=${startDate}&endDate=${endDate}`).then(res => {
                this.dataList = res
            })
        },

        // 监听数据前置事件
        onDataBefore(data) {
            this.getTableData(data.date[0].split("T")[0], data.date[1].split("T")[0])
        },

        onReset() {
            this.onLoadData()
        },
    },
}
</script>
<style lang="less"></style>
